<template>
  <div>
    <hero />
    <our-body-section id="#ourbody" />
    <about-detox id="#about-detox" :mobileView="aboutMobileView" />
    <bionizer
      :mobileView="bionizerMobileView"
      :bionizerInfoMobileView="bionizerInfoMobileView"
    />
    <benefits id="#benefits" />
    <!-- <contact-us-section /> -->
    <accordion-section id="#faq" />
  </div>
</template>
<script>
import Hero from "@/components/TherapyPage/Hero.vue";
import OurBodySection from "@/components/TherapyPage/OurBodySection.vue";
import AboutDetox from "@/components/TherapyPage/AboutDetox.vue";
import Bionizer from "@/components/TherapyPage/Bionizer.vue";
import Benefits from "@/components/TherapyPage/Benefits.vue";
import AccordionSection from "@/components/TherapyPage/AccordionSection.vue";
// import ContactUsSection from "@/components/TherapyPage/ContactUsSection.vue";

export default {
  name: "Therapy",
  components: {
    Hero,
    OurBodySection,
    AboutDetox,
    Bionizer,
    Benefits,
    AccordionSection,
    // ContactUsSection,
  },
  metaInfo: {
    title: "Detox Therapy",
    titleTemplate: "%s |  Alpha Global Wellness| Quantum Detox",
    meta: {
      name: "description",
      content:
        "Quantum Detox Therapy, a natural healing method through mordern technology!",
    },
  },
  data() {
    return {
      aboutMobileView: false,
      bionizerMobileView: false,
      bionizerInfoMobileView: false,
    };
  },
  methods: {
    onResize() {
      var innerWidth = window.innerWidth;
      if (innerWidth < 1050) {
        this.aboutMobileView = true;
      } else {
        this.aboutMobileView = false;
      }
      if (innerWidth < 630) {
        this.bionizerMobileView = true;
      } else {
        this.bionizerMobileView = false;
      }
      if (innerWidth < 830) {
        this.bionizerInfoMobileView = true;
      } else {
        this.bionizerInfoMobileView = false;
      }
    },
    scollToAnchor() {
      this.$nextTick(() => {
        if (this.$route.hash) {
          console.log(this.$route.hash);
          const top = document.getElementById(this.$route.hash).offsetTop;
          window.scrollTo(0, top);
        }
      });
    },
  },
  watch: {
    $route: function () {
      this.scollToAnchor();
    },
  },
  mounted() {
    this.scollToAnchor();
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 100px 0 0 0;
}
.title {
  font-weight: bold;
  font-size: 2em;
}
.content {
  margin: auto;
  width: 70%;
  padding-top: 20px;
  font-size: 1.3em;
  line-height: 1.2;
}
</style>
