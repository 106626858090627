<template>
  <div class="benefits-section benefits-section--brown">
    <div class="benefits-container">
      <div class="benefits-pic-wrapper">
        <picture>
          <source
            :srcset="
              require(`@/assets/images/therapy/Progressive_and_Preventive_Benefits.webp`)
            "
            type="image/webp"
          />
          <source
            :srcset="
              require(`@/assets/images/therapy/Progressive_and_Preventive_Benefits.jpg`)
            "
            type="image/jpeg"
          />
          <img
            class="benefits-pic"
            :src="
              require(`@/assets/images/therapy/Progressive_and_Preventive_Benefits.jpg`)
            "
            alt="pic"
          />
        </picture>
      </div>
      <div class="benefits-section-content-wrapper">
        <p class="title">Progressive and Preventive Benefits</p>
        <p class="content">
          Just like a car or air-conditioner that requires regular servicing,
          our body needs regular maintenance for better health. With consistent
          weekly detox, your body feels lighter and healthier in many ways such
          as:

          <br />
        </p>
        <div class="content-list" data-aos="fade-left">
          <img
            :src="require(`@/assets/images/therapy/tick.png`)"
            alt="pic"
            height="25px"
            width="25px"
          />
          <p class="content">Greater energy level and mental clarity</p>
        </div>
        <div class="content-list" data-aos="fade-left">
          <img
            :src="require(`@/assets/images/therapy/tick.png`)"
            alt="pic"
            height="25px"
            width="25px"
          />
          <p class="content">Relieve joint pain</p>
        </div>
        <div class="content-list" data-aos="fade-left">
          <img
            :src="require(`@/assets/images/therapy/tick.png`)"
            alt="pic"
            height="25px"
            width="25px"
          />
          <p class="content">Better digestion and absorption of nutrients</p>
        </div>
        <div class="content-list" data-aos="fade-left">
          <img
            :src="require(`@/assets/images/therapy/tick.png`)"
            alt="pic"
            height="25px"
            width="25px"
          />
          <p class="content">Better bowel movement</p>
        </div>
        <div class="content-list" data-aos="fade-left">
          <img
            :src="require(`@/assets/images/therapy/tick.png`)"
            alt="pic"
            height="25px"
            width="25px"
          />
          <p class="content">Healthier weight management</p>
        </div>
        <div class="content-list" data-aos="fade-left">
          <img
            :src="require(`@/assets/images/therapy/tick.png`)"
            alt="pic"
            height="25px"
            width="25px"
          />
          <p class="content">Better immune system</p>
        </div>

        <p class="content">
          Whatever ailments you may have, come and experience the benefits of
          Quantum Cellular Detox and feel the difference
          <span class="content-nowrap">for yourself!</span>
        </p>
        <p class="content content-footer">
          <br />
          <b><i>"A little </i></b><b class="darkGreen">PROGRESS</b>
          <i><b> adds up to </b></i><b class="purple">BIG RESULTS!</b
          ><b><i>"</i></b
          ><br /><br />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "benefits-section",
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 2em;
}
.benefits-section{
  padding-bottom: 60px;
}
.benefits-section--brown {
  background-color: #e8cf8f;
}
.benefits-container {
  padding: 80px 100px 80px 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.content {
  font-size: 1.3em;
  line-height: 1.2;
  max-width: 600px;
}
.content-nowrap {
  white-space: nowrap;
}
.content-footer {
  font-size: 1.6em;
}
.content-list {
  display: flex;
  flex-direction: row;
  max-width: 500px;
  align-items: flex-start;
  margin-bottom: 8px;
  img {
    padding-top: 3px;
    padding-right: 10px;
  }
  p {
    margin: 0px;
    text-align: left;
  }
}
.benefits-section-content-wrapper {
  width: 50%;
  text-align: left;
  padding: 0;
}
.benefits-pic-wrapper {
  width: 50%;
  padding-right: 130px;
  border-radius: 25px;
  border-bottom: 0px;
  text-align: right;
  margin-top: 40px;
  .benefits-pic {
    max-width: 800px;
    border-radius: 19px;
    height: auto;
  }
}
.purple {
  color: #ff1bff;
}
.darkGreen {
  color: #214d15;
}

@media only screen and (max-width: 1650px) {
  .benefits-container {
    flex-direction: column;
    align-items: center;
    padding: 0 30px 40px 30px;
  }
  .benefits-pic-wrapper {
    padding: 0;
    width: auto;
  }
  .benefits-section-content-wrapper {
    padding: 0;
    width: auto;
    text-align: center;
  }
}
</style>
