<template>
  <section>
    <!-- <hero /> -->
    <product-section />
  </section>
</template>
<script>
// @ is an alias to /src
// import ProductHero from "@/components/ProductPage/ProductHero.vue";
import ProductSection from "@/components/ProductPage/ProductSection.vue";

export default {
  name: "Product",
  components: {
    // hero: ProductHero,
    ProductSection,
  },
  metaInfo: {
    title: "Products",
    meta: {
      name: "description",
      content:
        "Quantum Detox Therapy, a natural healing method through mordern technology! Such as Holographic Harmoniser, Hydrogen Water Bottle, Alpha MWET Device",
    },
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 100px 0 0 0;
  background-color: #aec99c;
}
</style>
