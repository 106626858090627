<template>
  <section class="about-detox-section">
    <div class="about-detox-container">
      <p class="title">About Quantum Cellular Detox Therapy</p>
      <p class="content content--no-padding">
        Based on the theory of Quantum Physics proven and supported by Carlo
        Rubbia <span class="nowrap">(Nobel Prize Winner 1984),</span>
      </p>
      <p class="content content--no-padding">
        Richard Feynman and S.Tomonaga (Nobel Prize Winner in Physics 1965),
      </p>
      <br v-if="mobileView" />
      <p class="content content--no-padding">
        Quantum Cellular Detox is a preventive and
        <span class="nowrap">deep-cleansing therapy</span>
      </p>
      <p class="content content--no-padding">
        that enables the body system to<b> Regenerate, Renew and Self-Heal!</b>
        <br />
        <br />
        <br />
      </p>
      <div class="card-container" v-if="mobileView">
        <div
          class="content-card shadow"
          data-aos="fade-left"
          data-aos-offset="60"
        >
          <img
            class=""
            src="@/assets/images/therapy/regenerate_icon.png"
            alt="pic"
          />
          <p class="title content-title"><b>Regenerate</b></p>
          <b>(Initial Phase)</b>
          <p class="content">
            Quantum Cellular Detox uses healthy frequencies to stimulate and
            repair the body cells and tissue. It eliminates accumulated toxins
            through a safe and systematic process.

            <br />
          </p>
        </div>
        <div
          class="content-card shadow"
          data-aos="fade-right"
          data-aos-offset="60"
        >
          <img
            class=""
            src="@/assets/images/therapy/renew_icon.png"
            alt="pic"
          />
          <p class="title content-title"><b>Renew</b></p>
          <b>(Transformational Phase)</b>
          <p class="content">
            Reduced toxins in the body system helps to energise the cells and
            promotes a healthier cellular activity.
            <br />
          </p>
        </div>
        <div
          class="content-card shadow"
          data-aos="fade-left"
          data-aos-offset="60"
        >
          <img
            class=""
            src="@/assets/images/therapy/self_heal_icon.png"
            alt="pic"
          />
          <p class="title content-title"><b>Self-Heal</b></p>
          <b>(Optimal Phase)</b>
          <p class="content">
            Healthy cells enable the body to function effectively, strengthen
            the immune system and enhance the body’s innate ability to heal.
            <br />
          </p>
        </div>
      </div>
      <div class="pyramid-container" v-if="!mobileView">
        <div class="pyramid-wrapper" data-aos="zoom-in-up">
          <div class="pyramid-1" />
          <div class="pyramid-content pyramid-1-content" data-aos-offset="80">
            <img
              class=""
              src="@/assets/images/therapy/self_heal_icon.png"
              alt="pic"
            />
            <b class="title content-title">Self-Heal</b>
            <b>(Optimal Phase)</b>
            <p class="content">
              Healthy cells enable the body to function effectively, strengthen
              the immune system and enhance the body’s innate ability to heal.
              <br />
            </p>
          </div>
        </div>
        <div class="pyramid-wrapper" data-aos="zoom-in-up" data-aos-offset="80">
          <div class="pyramid-2-depth" />
          <div class="pyramid-content-wrapper">
            <div class="pyramid-2" />
            <div class="pyramid-content">
              <img
                class=""
                src="@/assets/images/therapy/renew_icon.png"
                alt="pic"
              />
              <b class="title content-title">Renew</b>
              <b>(Transformational Phase)</b>
              <p class="content">
                Reduced toxins in the body system helps to energise the cells
                and promotes a healthier cellular activity.
                <br />
              </p>
            </div>
          </div>
        </div>
        <div class="pyramid-wrapper" data-aos="zoom-in-up" data-aos-offset="80">
          <div class="pyramid-3-depth" />
          <div class="pyramid-content-wrapper">
            <div class="pyramid-3" />
            <div class="pyramid-content">
              <img
                class=""
                src="@/assets/images/therapy/regenerate_icon.png"
                alt="pic"
              />
              <b class="title content-title">Regenerate</b>
              <b>(Initial Phase)</b>
              <p class="content">
                Quantum Cellular Detox uses healthy frequencies to stimulate and
                repair the body cells and tissue. It eliminates accumulated
                toxins through a safe and systematic process.

                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <b class="content content-footer">
        Quantum energy-based treatment is widely used by doctors in Germany,
        Europe, Korea, and increasingly in other parts of the world.
        <br />
      </b>
    </div>
  </section>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "our-body-section",
  props: ["mobileView"],
  mounted() {
    window.addEventListener("scroll", function () {
      if (window.scrollY < 10) {
        AOS.refresh();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.about-detox-section {
  padding-top: 30px;
  background-color: #aec99c;
}
.about-detox-container {
  padding: 0px 30px 80px 30px;
}
.title {
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}
.content {
  margin: auto;
  width: 100%;
  padding: 20px 0px;
  font-size: 1.3em;
  line-height: 1.2;
  max-width: 1000px;
  text-align: center;
}
.content--no-padding {
  padding: 1.5px;
}
.content-footer {
  font-size: 1.5em;
}
.content-title {
  margin: 0px;
  font-size: 1.5em;
}
.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  .content-card {
    background-color: #fae7b6;
    padding: 30px;
    margin: 10px 0;
    max-width: 400px;
  }
}
.pyramid-container {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pyramid-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pyramid-content-wrapper {
      position: relative;
    }
    .pyramid-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        font-size: 1.1em;
      }
    }
  }
}
.pyramid-1-content {
  padding-top: 80px;
}
.pyramid-1 {
  width: 0;
  height: 0;
  border-left: 240px solid transparent;
  border-right: 240px solid transparent;
  border-bottom: 400px solid #fae7b6;
}
.pyramid-2 {
  border-bottom: 260px solid #fae7b6;
  border-left: 155px solid transparent;
  border-right: 155px solid transparent;
  height: 0;
  width: 515px;
}
.pyramid-3 {
  border-bottom: 280px solid #fae7b6;
  border-left: 165px solid transparent;
  border-right: 165px solid transparent;
  height: 0;
  width: 863px;
}
.pyramid-2-depth {
  border-bottom: 30px solid#baa673;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  height: 0;
  width: 220px;
}
.pyramid-3-depth {
  border-bottom: 30px solid #baa673;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  height: 0;
  width: 560px;
}
.shadow {
  -webkit-box-shadow: 7px 8px 13px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 7px 8px 13px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 7px 8px 13px 0px rgb(0, 0, 0, 0.36);
}
.nowrap {
  white-space: nowrap;
}
@media only screen and (max-width: 1350px) {
  .content {
    width: auto;
  }
  .about-detox-container {
    padding: 0px 30px 80px 30px;
  }
  .pyramid-container {
    transform: scale(0.8, 0.8);
    padding-bottom: 0;
  }
}
</style>
