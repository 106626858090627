<template>
  <section class="bionizer-section">
    <div class="bionizer-top-content" id="#bionizer">
      <div class="img-container">
        <div class="img-wrapper-container">
          <div class="img-wrapper img-wrapper-round" v-if="!mobileView">
            <picture id="bionizer-img-first">
              <source
                :srcset="
                  require(`@/assets/images/therapy/Quantum_Detox_Bionizer-2.webp`)
                "
                type="image/webp"
              />
              <source
                :srcset="
                  require(`@/assets/images/therapy/Quantum_Detox_Bionizer-2.jpg`)
                "
                type="image/jpeg"
              />
              <img
                class="bionizer-img shadow"
                :src="
                  require(`@/assets/images/therapy/Quantum_Detox_Bionizer-2.jpg`)
                "
                alt="pic"
              />
            </picture>
          </div>
          <div class="img-wrapper img-wrapper-round">
            <div class="img-top-spacing" />
            <picture id="bionizer-img-second">
              <source
                :srcset="
                  require(`@/assets/images/therapy/Quantum_Detox_Bionizer-1.webp`)
                "
                type="image/webp"
              />
              <source
                :srcset="
                  require(`@/assets/images/therapy/Quantum_Detox_Bionizer-1.jpg`)
                "
                type="image/jpeg"
              />
              <img
                class="bionizer-img shadow"
                :src="
                  require(`@/assets/images/therapy/Quantum_Detox_Bionizer-1.jpg`)
                "
                alt="pic"
              />
            </picture>
          </div>
        </div>
      </div>
      <div class="img-wrapper img-wrapper-round" v-if="mobileView">
        <div class="img-top-spacing" />
        <picture id="bionizer-img-third">
          <source
            :srcset="
              require(`@/assets/images/therapy/Quantum_Detox_Bionizer-2.webp`)
            "
            type="image/webp"
          />
          <source
            :srcset="
              require(`@/assets/images/therapy/Quantum_Detox_Bionizer-2.jpg`)
            "
            type="image/jpeg"
          />
          <img
            class="bionizer-img shadow"
            :src="
              require(`@/assets/images/therapy/Quantum_Detox_Bionizer-2.jpg`)
            "
            alt="pic"
          />
        </picture>
      </div>
      <div class="img-wordings-wrapper">
        <p class="img-wordings-title">Quantum Detox Bionizer</p>
        <p class="img-wordings-content">
          Quantum Detox Bionizer is manufactured in Korea with advanced quantum
          technology from Germany. It administers a safe and non-invasive
          therapy that uses gentle energy waves and frequency patterns to
          enhance overall health and wellness.
          <br />
          <br />
          It stimulates and strengthens the body cells, breaks down toxin
          molecules within the cellular layers, and restores the bioenergetic
          balance in the body system.
          <br />
        </p>
      </div>
    </div>
    <bionizer-info v-if="!bionizerInfoMobileView" id="#howDoesitWork" />
    <bionizer-info-mobile v-if="bionizerInfoMobileView" id="#howDoesitWork" />
  </section>
</template>
<script>
import BionizerInfoMobile from "@/components/TherapyPage/BionizerInfoMobile.vue";
import BionizerInfo from "@/components/TherapyPage/BionizerInfo.vue";
export default {
  name: "our-body-section",
  props: ["mobileView", "bionizerInfoMobileView"],
  components: {
    BionizerInfo,
    BionizerInfoMobile,
  },
};
</script>
<style lang="scss" scoped>
.bionizer-section {
  background-color: #e8cf8f;
  padding-bottom: 50px;
}
.shadow {
  -webkit-box-shadow: 7px 8px 13px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 7px 8px 13px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 7px 8px 13px 0px rgb(0, 0, 0, 0.36);
}
.bionizer-top-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.img-container {
  position: relative;
  width: 50%;
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
  .img-wrapper-container {
    display: inline-flex;
    .img-top-spacing {
      height: 70px;
    }
    #bionizer-img-first,
    #bionizer-img-third {
      position: relative;
      overflow: hidden;
      margin-right: -25px;
      .bionizer-img {
        width: 100%;
        height: auto;
      }
    }
    #bionizer-img-second {
      position: relative;
      overflow: hidden;
      margin-left: -25px;
      width: 350px;
      .bionizer-img {
        width: 350px;
        height: auto;
      }
    }
  }
}
.img-wrapper-round {
  border-radius: 25px;
  border-bottom: 0px;
  .bionizer-img {
    border-radius: 19px;
  }
}
.img-wordings-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 40px;
  .img-wordings-title {
    font-weight: bold;
    font-size: 2em;
    text-align: left;
  }
  .img-wordings-content {
    width: 70%;
    max-width: 600px;
    font-size: 1.3em;
    line-height: 1.2;
    text-align: left;
  }
}

@media only screen and(max-width:630px) {
  #bionizer-img-second {
    margin-left: 0 !important;
    width: 100% !important;
    .bionizer-img {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .bionizer-top-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .img-container {
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    .img-wrapper-container {
      padding: 0px 20px;
    }
  }
  .img-wordings-wrapper {
    padding: 0px 30px;
    width: auto;
    align-items: center;
    .img-wordings-title {
      text-align: center !important;
    }
    .img-wordings-content {
      width: auto;
      text-align: center !important;
    }
  }
}
@media only screen and (max-width: 1370px) {
  .img-wordings-wrapper {
    .img-wordings-content {
      width: auto;
    }
  }
}
</style>
