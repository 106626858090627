<template>
  <div class="background">
    <div class="product-pic-container">
      <picture>
        <source
          :srcset="require(`@/assets/images/products/${product.path}.webp`)"
          type="image/webp"
        />
        <source
          :srcset="require(`@/assets/images/products/${product.path}.jpg`)"
          type="image/jpeg"
        />
        <img
          class="product-pic"
          :src="require(`@/assets/images/products/${product.path}.jpg`)"
          alt="product-pic"
        />
      </picture>
    </div>
    <div class="product-content">
      <h1 class="product-name">
        {{ product.title }}
      </h1>
      <span class="product-desc">
        {{ product.description }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  props: ["product"],
};
</script>
<style lang="scss" scoped>
.background {
  background-color: #f4f4f0;
  border-radius: 25px;
  border: 5px solid transparent;
  &:hover {
    border: 5px solid #107e79;
  }
}
.product-pic {
  max-width: 100%;
  max-height: 80%;
  display: block;
  margin: auto;
  border-radius: 19px 19px 0 0;
}
.product-name {
  font-size: 1.3em;
  padding-top: 0.83em;
  margin-top: 0;
}
.product-content {
  text-align: left;
  padding: 1.8em;
  padding-top: 0 !important;
  padding-bottom: 0.7em;
  border-top: 0px;
  border-radius: 0px 0px 25px 25px;
  color: black !important;
  text-decoration: none !important;
}
.product-pic-container {
  border-radius: 25px 25px 0 0;
  border-bottom: 0px;
  background-color: #e4d8cf;
}

// .product-price {
//   font-size: 1.35em;
//   font-weight: 500;
//   display: block;
//   padding-bottom: 0.7em;
//   margin-bottom: 0;
// }
</style>
