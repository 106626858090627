<template>
  <div class="accordion-section">
    <span class="title">FAQ</span>
    <accordion>
      <accordion-item
        v-for="item in items"
        :key="item.title"
        class="accordion-item"
      >
        <template slot="accordion-trigger">
          <h3>{{ item.title }}</h3>
          <font-awesome-icon
            class="accordion-icon"
            :icon="['fa', 'angle-down']"
            size="2x"
          />
        </template>
        <template slot="accordion-content">
          <div>
            <p class="item-content">{{ item.content }}</p>
            <ul class="item-list">
              <li v-for="pointer in item.pointers" :key="pointer">
                {{ pointer }}
              </li>
            </ul>
          </div>
        </template>
      </accordion-item>
    </accordion>
  </div>
</template>
<script>
import Accordion from "./Accordion.vue";
import AccordionItem from "./AccordionItem.vue";
export default {
  name: "accordion-section",
  components: {
    Accordion,
    AccordionItem,
  },
  data() {
    return {
      items: [
        {
          title: "Is Quantum Cellular Detox safe?",
          content:
            "Quantum Cellular Detox is a naturopathy that uses quantum physics supported and proven by well-known physicists and Nobel Prize Winners, Carlo Rubbia and Shin’ichiro Tomonaga. \n\n Quantum Cellular Detox is a safe, non-invasive and complementary therapy widely used by doctors in Germany, Korea and many other parts of the world. It has helped many of our customers regain better health.",
          pointers: [
            "Neutralised cholesterol levels",
            "Eased constipation, relief joint pain and numbness",
            "Better hormones balance (e.g. less irritable)",
            "Better immune system (e.g. not falling ill as often)",
            "Better energy level",
            "Improved skin conditions",
            "Reduced bloatedness",
          ],
        },
        {
          title:
            "What is the difference between someone who has gone through Quantum Cellular Detox and someone who hasn’t?",
          content:
            "A regularly detoxed person has a body system that is more efficient at clearing toxins and will feel more energised and less lethargic. With a better immunity system, a person is undoubtedly healthier. \n\n Quantum Cellular Detox is likened to a person who exercises regularly and whose health condition will improve over time. It is also similar to the servicing of an air-conditioning system. \n\n Regular maintenance ensures that it is less likely to be choked and helps to improve performance and efficiency.",
          pointers: [],
        },
        {
          title: "What are toxins?",
          content:
            "Toxins are all around us! We are constantly exposed to chemicals from the environment as well as chemical-laden foods and products. Toxins can be found in / caused by:",
          pointers: [
            "Toxic chemicals such as pesticides, preservatives, food additives, environmental pollution, cigarette smoke and heavy metals.",
            "Radiation, free radicals and ultraviolet rays from the sun.",
            "Bacteria, yeast and parasites found in the body.",
            "Emotional stress and pressure, lack of sleep and poor diet.",
          ],
        },
        {
          title: "What are the effects of toxins?",
          content:
            "Our body is designed to heal, regenerate and renew itself. It is also designed to cope with toxins by neutralising and removing them from our body. \n\n It is almost impossible to avoid the build-up of toxins in our body, which means our liver and digestive system can easily be overwhelmed by them. Toxins hinder the normal functions of the body, causing stagnation, congestion, illness, diseases and chronic conditions throughout the body. \n\n When toxins are not removed regularly from the liver and digestive system, they will be stored in the body tissues and hinder the proper functions of the body. This can harm the overall health and sow the seeds of future illnesses.",
          pointers: [],
        },
        {
          title: "What is toxin overload?",
          content:
            "Toxic overload occurs when there are more toxins than what the body can eliminate. When we detox our body, we are helping our body to:",
          pointers: [
            "Cleanse and remove pressure from the liver (the most important organ for removing harmful substances from the body), so that it can renew itself to better perform other vital functions.",
            "Reduce blockage in the digestive and lymphatic systems to allow a better flow of nutrients throughout the body.",
            "Remove toxins from the cells to the blood for elimination.",
            "Reduce future storage of harmful toxins and chemicals in our body.",
            "Restore vital energy to cleanse and regenerate our cells and tissues, allowing our body to naturally heal itself and help to prevent cancer and the onset of other diseases.",
          ],
        },
        {
          title: "What difference will I feel after each therapy session?",
          content:
            "As some of the harmful toxins are released during each detox session, you will feel much lighter, more energised and less fatigue. The rest of the toxins will be discharged through urine and stool over two to three consecutive days. Hence, it is important to drink sufficient water daily to facilitate this process.",
          pointers: [],
        },
        {
          title: "Can Quantum Cellular Detox treat my illness and pain?",
          content:
            "Quantum Cellular Detox Therapy is not a medical treatment and should not be used to replace any required medical treatment. Quantum Cellular Detox is a naturopathy. It is a non-invasive and complementary therapy based on self-healing and vitalism that uses a preventive approach. \n\n The Quantum Detox Bionizer is designed to spring-clean the body, giving it the nutrients it needs to perform at an optimal level. It removes toxins that have been stored, supports and cleans the organs that are responsible for the elimination of toxins, gets things moving so that our system can work efficiently. \n\n When we continue the detox therapy, we are gradually preventing toxins from building up and nesting in our cells, blood and organs. Over time, with a healthier body, a balanced diet and lifestyle, our body will be able to restore its ability to heal, to regenerate and to recover. ",
          pointers: [],
        },
        {
          title:
            "How do I know that the substances discharged into the water are toxins?",
          content:
            "The Quantum Detox Bionizer is designed to revitalise our cells and allow them to resonate at a healthier frequency. \n\n During this process, toxins and waste matters are dislodged into the bloodstream for elimination through urine, stool and perspiration. \n\n Not everyone clears the same toxins. Some may clear uric acid while others may clear more heavy metals or blood clots. Different individuals have different toxins due to different diet and lifestyle. The types of toxins eliminated through the therapy will hence differ among individuals. ",
          pointers: [],
        },
        {
          title:
            "Why does it appear that colored residues are coming out from the Ionizing Device into the basin?",
          content:
            "The Ionizing Device and sea salt water in the basin generate negative ions. Just like the principle of magnetism, the negative ions will attract the positive ions (toxins). When toxins are flushed out into the water, they are in molecular form (colourless and not visible to our naked eyes). \n\n However, as more toxins of the same type gather, distinct colours are formed. Since the negative ions near the Ionizing Device are stronger, more toxins will gather around the Ionizing Device.",
          pointers: [],
        },
        {
          title:
            "Why are the colored residues so excessive and dirty in the water, and what should I do?",
          content:
            "When the water is black, it implies that the body is acidic and heaty. This is due to stress, lack of sleep or too much radiation. \n\n In Traditional Chinese Medical term, it means your body is heaty. When the body is too acidic, it causes corrosion in the body which creates more toxins. This condition has a high tendency to attract cancerous growth. \n\n As you detox your body regularly, your body cells become stronger and will regain its ability to detox on its own. Regardless of which detox level you are on, it is important to adjust your lifestyle and diet for a better result. For some of our customers who are unable to change their existing lifestyle, they detox more regularly to balance their body to prevent toxic overload. ",
          pointers: [],
        },
        {
          title: "How often do I need to detox?",
          content:
            "There are eight levels of increasing detox intensity. At the beginning, it is recommended to come once or twice weekly until all eight levels have been completed. \n\n Thereafter, you may continue fortnightly or monthly for maintenance. This continual detox is recommended to prevent the build-up of toxins which our bodies absorb and produce on a daily basis.",
          pointers: [],
        },
        {
          title: "Is Quantum Cellular Detox suitable for everyone?",
          content:
            "Quantum Cellular Detox is suitable for most people. However, we recommend that people with the following conditions should refrain from the therapy",
          pointers: [
            "People with a pacemaker and any battery-operated device or implants",
            "People who are on heartbeat regulating device",
            "Expecting or breast-feeding mothers",
            "People who have just gone through organ transplant",
            "People who have had surgery within the past 3 months",
            "Children under 6 years old",
          ],
        },
      ],
    };
  },
  mounted() {
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  },
};
</script>
<style scoped lang="scss">
.accordion-section {
  padding: 150px 0px 50px 0px;
  background-color: #aec99c;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.accordion {
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  max-width: 1500px;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
.title {
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}
.accordion-item:hover {
  transform: scaleY(1.1);
}
.accordion__trigger {
  h3 {
    padding-right: 5px;
  }
}
.item-content {
  white-space: pre-line;
  margin-top: 0px;
}
.item-list {
  li {
    padding: 3px 0px;
  }
}
.accordion-icon{
  padding-left:20px;
}
</style>
