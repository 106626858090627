<template>
  <div>
    <div class="title tangerine-font"><span>Products Range</span></div>

    <div class="radiation container">
      <h1 class="category-title">Protects from Radiation</h1>
      <div v-bind:key="product.id" v-for="product in products">
        <div class="product-container shadow" v-if="product.categoryId === 1">
          <router-link
            class="routerLink"
            :to="{ path: `product/${product.id}` }"
          >
            <product v-bind:product="product" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="hydration container">
      <h1 class="category-title">Enhance Hydration & Anti-Ageing</h1>
      <div v-bind:key="product.id" v-for="product in products">
        <div class="product-container shadow" v-if="product.categoryId === 2">
          <router-link
            class="routerLink"
            :to="{ path: `product/${product.id}` }"
          >
            <product v-bind:product="product" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="wave container">
      <h1 class="category-title">Boost Circulation & Immune System</h1>
      <div v-bind:key="product.id" v-for="product in products">
        <div class="product-container shadow" v-if="product.categoryId === 3">
          <router-link
            class="routerLink"
            :to="{ path: `product/${product.id}` }"
          >
            <product v-bind:product="product" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Product from "./Product";
export default {
  name: "Hero",
  components: {
    Product,
  },
  data() {
    return {
      products: [
        {
          id: 1,
          categoryId: 1,
          title: "Holographic Harmoniser",
          description:
            "Designed to protect you from the side-effects of Electromagnetic Waves(EMW)",
          path: "HH/HHLandingPage",
          price: "SGD 38",
        },
        {
          id: 2,
          categoryId: 2,
          title: "NU Hydrogen Water",
          description:
            "Hydrogen Water rich in antioxidant against free radicals",
          path: "NHWB/NHWBProductPage",
          price: "SGD 138",
        },
        {
          id: 3,
          categoryId: 3,
          title: "Alpha MWET Device",
          description:
            "Unique combination of energy waves to stimulate bioenergy(Qi) and blood circulation",
          path: "AMWET/AMWETLandingPage",
          price: "SGD 850/mth",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.title {
  padding: 3rem;
}
.category-title {
  text-align: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.radiation {
  background-color: #d7af7d;
}
.hydration {
  background-color: #7cacce;
}
.wave {
  background-color: #a9ad78;
}
.product-container {
  display: inline-block;
  width: 325px;
  margin: 30px 10px 30px 10px;
  border-radius: 25px;
}

.shadow {
  -webkit-box-shadow: 7px 8px 13px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 7px 8px 13px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 7px 8px 13px 0px rgba(0, 0, 0, 0.36);
}
@media only screen and (max-width: 650px) {
  .product-container {
    width: 70vw !important;
    margin-top: 40px !important;
  }
  .title {
    font-size: 2.2rem;
    padding: 1.5em;
  }
  .container {
    flex-direction: column !important;
  }
  .category-title {
    width: 80vw;
    text-align: center;
    padding: 0;
  }
}

.routerLink {
  text-decoration: none;
}
</style>
