<template>
  <section class="our-body-section our-body-section--brown">
    <div class="our-body-container">
      <div class="our-body-pic-wrapper">
        <picture>
          <source
            :srcset="
              require(`@/assets/images/therapy/our_body_needs_help.webp`)
            "
            type="image/webp"
          />
          <source
            :srcset="require(`@/assets/images/therapy/our_body_needs_help.jpg`)"
            type="image/jpeg"
          />
          <img
            class="our-body-pic"
            :src="require(`@/assets/images/therapy/our_body_needs_help.jpg`)"
            alt="pic"
            width="100%"
          />
        </picture>
      </div>
      <div class="our-body-section-content-wrapper">
        <p class="title">Our Body Needs Help</p>
        <p class="content">
          All living systems possess an innate biological field, or biofield.
          The bio-energy field within the human body is constantly resonating.
          <br />
          <br />
          This natural resonance is important in maintaining good health and
          wellness in our body system.
          <br />
          <br />
          Toxins are responsible for most of our health problems. The
          accumulation of toxins disrupts the natural resonance in us, hinders
          blood circulation, weakens the immune system, and may lead to chronic
          illnesses such as cancer, stroke and organ failure.
          <br />
        </p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "our-body-section",
};
</script>
<style lang="scss" scoped>
.our-body-section {
  padding-top: 0px;
}
.title {
  font-weight: bold;
  font-size: 2em;
  text-align: left;
  max-width: 600px;
}
.our-body-section--brown {
  background-color: #e8cf8f;
}
.our-body-container {
  padding: 80px 100px 80px 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.content {
  font-size: 1.3em;
  line-height: 1.2;
  max-width: 600px;
  text-align: left;
}
.our-body-section-content-wrapper {
  width: 50%;
  text-align: left;
  padding: 0;
  .title {
    margin-top: 0 !important;
  }
}
.our-body-pic-wrapper {
  width: 50%;
  padding-right: 80px;
  border-radius: 25px;
  border-bottom: 0px;
  text-align: right;
  .our-body-pic {
    max-width: 600px;
    border-radius: 19px;
    height: auto;
  }
}

@media only screen and (max-width: 1350px) {
  .our-body-container {
    flex-direction: column;
    align-items: center;
    padding: 50px 30px 40px 30px;
  }
  .our-body-pic-wrapper {
    padding: 0;
    width: auto;
  }
  .our-body-section-content-wrapper {
    padding: 0;
    width: auto;
    text-align: center;
  }
  .content,
  .title {
    text-align: center;
  }
}
</style>
