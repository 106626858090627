<template>
  <div class="therapy-page-banner-wrapper">
    <picture>
      <source
        media="(max-width:1199px)"
        :srcset="
          require(`@/assets/images/backgrounds/therapy-banner-mobile.webp`)
        "
        type="image/webp"
      />
      <source
        media="(max-width:1199px)"
        :srcset="
          require(`@/assets/images/backgrounds/therapy-banner-mobile.jpg`)
        "
        type="image/jpeg"
      />
      <source
        media="(min-width:1200px)"
        :srcset="require(`@/assets/images/backgrounds/therapy-banner.webp`)"
        type="image/webp"
      />
      <source
        media="(min-width:1200px)"
        :srcset="require(`@/assets/images/backgrounds/therapy-banner.jpg`)"
        type="image/jpeg"
      />
      <img
        class="therapy-banner-img"
        :src="require(`@/assets/images/backgrounds/about-us-banner.jpg`)"
        alt="therapy-banner"
        width="100%"
      />
    </picture>
  </div>
</template>

<script>
export default {
  name: "hero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.therapy-page-banner-wrapper {
  font-size: 0;
}
.therapy-banner-img {
  max-height: 90vh;
}
</style>
